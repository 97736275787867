@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap'); */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#root,
.app {
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  font-family: 'Nunito' !important;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  box-sizing: border-box;
}

.MuiButton-root:hover {
  /* background-color: #fe4066 !important */
  /* background-color: #222222 !important */
}

.main {
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#root {}
#header {
  z-index: 999 !important;
  position: relative;
  width: 100%;
  top: 0;
  position: -webkit-sticky;
  position: sticky

}

#footer {
  left: 0;
  bottom: 0;
  width: 100%;
  /* margin-top: 40px; */
}

.auth-wrapper {
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.body {
  position: relative;
  /* pointer-events: none; */
}

.des {
  text-align: center;
  color: #222222;
}

#toSignup:hover {
  text-decoration: underline;
  cursor: pointer;
}

.pending {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  opacity: 0.5;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
  position: relative;
}

.pending-icon__active {
  width: 100px !important;
  height: 100px !important;
  position: absolute !important;
  top: 30% !important;
  left: 40% !important;
  z-index: 99999;

}

.pending-icon__inactive {
  display: none !important;
}

.logo_out {
  width: 500px;
  height: 500px;
  align-content: center;
}
#engine{
  display: flex;
  justify-content: center;
}
.header {
    width: 100%;
    height: 80px;
    color: #ecf0f0;
    background: linear-gradient(147deg, #000000 0%, #434343 74%);
    display: block;
    border-bottom: 1px solid #ecf0f0;
    padding-bottom: 10px;

}

.logo {
    height: 80px;
}

#username {
    height: 80px;
}

#username>p>span {
    color: #cbaf13;
    font-weight: 500;
}

#username>p {
    position: relative;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 25px;
    font-size: large;
    color: #000000;

}

.dev-center,
.profile {
    background-color: transparent;
    color: #000000;
    position: relative;
    margin-right: 15px;
    border: 1px solid;
    border-radius: 40px;

}

.support-group,
.profile-group {
    display: flex;
    justify-content: flex-end;
    height: 80px;
}

.support-group>button>a {
    color: #000000;
    font-family: 'Nunito' !important;
    font-size: 18px !important;
    text-transform: capitalize;

}

#basic-button-text {
    text-transform: capitalize;
    font-family: 'Nunito' !important;
    font-size: 22px !important;
    font-weight: bolder;
}

#basic-button-text {
    color: white !important;
}

#basic-button-text:hover {
    color: #fe4066 !important;
}

#logout {
    color: red;
}

.profile-btn {
    margin-right: 10px;
}

.profile>img {
    width: 30px;
    height: 30px;
    border: 1px solid;
    color: #f2d001;
    border-radius: 50%;
    margin-right: 5px;
}
.apilist-title {
    margin-left: 5%;
    font-size: xx-large;
    margin-bottom: 15px;
}

.apilist-body {
    margin-left: 10%;
    padding-bottom: 0;
}

.apilist-list {
    font-size: 17px;
    /* margin-left: 10%; */
    margin-bottom: 0;

}

.apilist-span {
    color: red
}

.apilist-api {
    margin-left: 13%;
    margin-bottom: 0;
}

.apilist-api2 {
    margin-left: 15%;
    margin-bottom: 0;

}

.apilist-api3 {
    margin-left: 17%;
    margin-bottom: 0;

}

.apilist-api4 {
    margin-left: 19%;
    margin-bottom: 0;

}

.apilist-api5 {
    margin-left: 21%;
    margin-bottom: 0;

}

.apilist-api6 {
    margin-left: 23%;
    margin-bottom: 0;

}

.api-wrapper-main {
    width: 100%;
    display: flex;
    justify-content: center;
}

.api-wrapper {
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
    width: 80%;
}

.api-copy {
    background-color: #f0f0f0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 10px;
    /* margin-left: 5% !important; */
}

.copy-button {
    position: absolute;
    right: 0;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

/* step-control */
.step-button {
    display: flex;
    justify-content: space-around;

}

.step-button>button {
    margin-top: 10px;
    width: 100px;
}

/* end */
/* step-1 */
.create-form {
    padding: 30px 30px 30px 40px !important;
    transition: all .3s;
    border: solid 1px;
    border-radius: 15px;
    margin: auto;
    background: transparent;
    margin-top: 5%;
}

.form-group>label {
    margin-left: 10px;
}
#button_item {
    color: #222222;
}



.create-project {
    background: linear-gradient(-45deg, #fffbfb, #fffbfb, #fffbfb, #fffbfb, #fffbfb, #fffbfb, #fffbfb);
}

/* end */
/* step-2 */
.upload-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 100px;
}

h3 {
    text-align: center;
}

#logo {
    width: 120px;
    height: 80px;
}

.creating {
    font-size: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #1976D2;
    z-index: 999;
}

/* end */
/*editor */
#editor-frame {
    height: 100vh;
    width: 100%;
}

.header-tools {
    height: 50px;
    width: 100%;
    position: fixed;
    margin-top: 10px;
}

.header-title {
    text-align: center;
    font-size: x-large;
    color: white;
    font-weight: bold;
    pointer-events: none;

}

.thumbnail-image.active {
    border: solid
}


/* thumbnail */

.thumbnail {
    margin: auto;
    margin-top: 15px;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    width: 100%;
    border: 1px solid;
    border-radius: 35px;
    margin-bottom: 10px;
    color: #e5e2e2;
    padding-top: 5px;
    max-width: -webkit-max-content;
    max-width: max-content;
    /* overflow: auto; */
    white-space: nowrap;

}

.thumbnail_small_screen {
    margin: auto;
    margin-top: 15px;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid;
    border-radius: 35px;
    margin-bottom: 10px;
    color: #e5e2e2;
    padding-top: 5px;
    max-width: 80%;
}

#img {
    width: 120px;
    height: 50px;
    display: block;
    border-radius: 5px;
    margin-right: 10px;
}

#img:active {
    box-shadow: 0 0 3pt 2pt cornflowerblue
}

.arrow {
    height: 50px;
    width: 50px;
    background: transparent;
    cursor: pointer;
    transition: -webkit-transform ease-in .1s;
    transition: transform ease-in .1s;
    transition: transform ease-in .1s, -webkit-transform ease-in .1s;
    display: inline-block;
}

.bi-arrow-left-short:before,
.bi-arrow-right-short:before {
    color: #222
}


/* thumbnail */

.thumbnail-wrapper {
    position: relative;
}

.thumbnail-wrapper:hover .thumbnail-image-remove {
    opacity: 1;
}

.thumbnail-image-remove {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -15px;
    opacity: 0;
    transition: .5s ease;
}

.thumbnail-image-remove-icon {
    position: relative;
    top: -6px;
    left: -10px;
}

.thumbnail-image:hover .thumbnail-image-remove {
    display: block;
}

.thumbnail-image-add-button {
    margin-bottom: 15px;
}

.thumbnail-image {
    z-index: 99999999999;
}


/* end thumbnail */


/* add label */

.picked-location-label {
    position: absolute;
    background: white;
}

.CreateLabelForm {
    width: 300px;
    height: 260px;
    border: 1px solid #e5e2e2;
    border-radius: 25px;
    background-color: transparent;
}

.CreateLinkForm {
    width: 300px;
    height: 160px;
    border: 1px solid #e5e2e2;
    border-radius: 25px;
    background-color: transparent;
}

.CreateLabelForm__options {
    margin-top: 13px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e5e2e2;
}

.tools {
    width: 100%;
    position: fixed;
    z-index: 10;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 30px;
}

.toolsbox>input {
    width: 250px;
}

.toolsbox>textarea {
    width: 250px;
    height: 90px;
}

.form-group>textarea {
    height: 100px;
    background-color: #e5e2e2 !important;
}

.form-group>input {
    background-color: #e5e2e2 !important;
}

.custom-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}

.hotspot-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 15px;
}

.spot-text-ele,
.spot-img-ele {
    position: absolute;
    min-width: 310px;
    max-width: 310px;
    min-height: 44px;
    color: #fff;
    background-color: rgba(0, 0, 0, .85);
    word-wrap: break-word;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
    padding: 12px;
    pointer-events: auto;
    cursor: auto;
}

.text-ele__describe {
    font-size: 15px;
    font-weight: 500;
    -webkit-text-size-adjust: none;
    color: #c8c8c8;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
}

.spot-text-ele {
    top: -6% !important;
}

.spot-img-ele {
    top: -15.5% !important;
}

.spot-vid-ele {
    top: -17% !important
}

.img-ele__img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

/* end label*/

/* linkspot */
.picked-location-link {
    position: absolute;
    background: white;
}

.preview-spot {
    text-align: center;
    position: absolute;
    bottom: -95px;
    left: -115px;
}

/* end linkspot */

/* header tools */

.vid-ele__vid {
    height: -webkit-max-content !important;
    height: max-content !important;
    width: -webkit-max-content !important;
    width: max-content !important;
}

.text-ele__title,
.img-ele__title,
.vid-ele__title {
    font-size: 16px;
    font-weight: 700;
    white-space: pre-wrap;
    margin: 0;
}

.toolsbox>.MuiDropzoneArea-root {
    min-height: 20px !important;
    max-height: 90px !important;
    height: 86.9px !important;
    width: 268px !important;
}

.toolsbox>.MuiDropzoneArea-root>.MuiDropzoneArea-textContainer>p {
    font-size: 1rem !important;
    margin-top: 15px !important;
}

.toolsbox>.MuiDropzoneArea-root>.MuiDropzoneArea-textContainer>svg {
    width: 25px !important;
    height: 25px !important;
    display: none;
}

.toolsbox__pickimage {
    min-width: 250px;
    height: 70px;
    margin-bottom: 15px;
}

/* end tools */
.img-wrapper {
    display: flex;
    overflow: hidden;
}

.adjustY {
    -webkit-transform: translateY(355px);
            transform: translateY(355px);
}

.adjustXleft {
    -webkit-transform: translate(170px, 160px);
            transform: translate(170px, 160px)
}

.adjustXRight {
    -webkit-transform: translate(-170px, 160px);
            transform: translate(-170px, 160px)
}

.adjustBotLeft {
    -webkit-transform: translate(170px, 20px);
            transform: translate(170px, 20px)
}

.adjustBotRight {
    -webkit-transform: translate(-170px, 20px);
            transform: translate(-170px, 20px)
}


.preview-link {
    position: absolute;
    min-width: 310px;
    max-width: 310px;
    min-height: 44px;
    color: #fff;
    background-color: rgba(0, 0, 0, .85);
    word-wrap: break-word;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
    padding: 12px;
    pointer-events: auto;
    cursor: auto;
    top: -15.9% !important;
    /* left: -0.% !important; */
}

/*tooltip */
/* .preview-link::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 45%;
    border-color: rgba(0, 0, 0, .85) transparent transparent transparent;
    border-style: solid;
    border-width: 15px;
} */

.preview-image {
    width: 286px;
    height: 200px;
}

.editinfo-dropzone>.MuiDropzoneArea-root {
    min-height: 20px !important;
    max-height: 90px !important;
    height: 86.9px !important;
}

.editinfo-dropzone>.MuiDropzoneArea-root>.MuiDropzoneArea-textContainer>p {
    font-size: 1rem !important;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
}

.editinfo-dropzone>.MuiDropzoneArea-root>.MuiDropzoneArea-textContainer>svg {
    width: 25px !important;
    height: 25px !important;
    display: none;
}



.transition-start {
    -webkit-filter: blur(0.5rem);
            filter: blur(0.5rem);
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

.transition-end {
    -webkit-filter: blur(0);
            filter: blur(0);
    -webkit-transition: all 0.5s ease-in-out;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
}
#preview{
    min-width: 100%;
    height: 100vh;
}
#label-input{
    font-size: 20px ;
}
#view{
    min-width: 100%;
    height: 100vh;
}
.icon-navbar {
    margin-right: 10px;
    width: 25px;
    height: 25px;
    color: black !important;
}

.dashboard {
    background-color: #f5f4f3;
}

.sidebar {
    background-color: #EFF1F8;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* background-image: linear-gradient(147deg, #000000 0%, #434343 74%); */

}

.sidenav---sidenav-toggle---1KRjR {
    color: black !important;

}

.sidenav---collapsed---LQDEv {
    transition: 500ms;
    border-right: 1px solid #f4f9fd;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    border-right: 1px solid #f4f9fd;
    transition: 500ms;
}

.sidenav---navtext---1AE_f>p {
    color: black !important;
}

.sidenav---sidenav-toggle---1KRjR>span {
    background-color: black !important;

}

.noproject {
    width: 400px;
    height: 450px;
}

/*end*/
::-webkit-scrollbar {
    width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #606060;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #909090;
}

#sortby {
    background-color: transparent !important;
    border: 2px solid gray;
    border-radius: 10px;
    margin-right: 30px;
    color: #cfe4f5;
}


.list-img {
    width: 145px;
    height: 70px;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 5px
}


#list-ul-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    border-width: thin;
    padding-bottom: 8px;
    width: 1220px;
}


.img-des->:last-child {
    font-weight: lighter;
}

#ellipsis {
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-width: 500px;
    overflow: hidden;
    opacity: 0.8;
    width: 300px;
}

.dropdown-toggle:empty::after {
    color: #ffffff;
}

.list-btn>button,
.list-btn>a {
    width: 40px !important;
    height: 40px !important;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    border: 2px solid #222222;
    background-color: #222222;
    border-radius: 15px;
    margin-left: 15px;
}

/* 




.list-btn>button>a {
    display: inherit;
    height: 40px;
}

.delete-button {
    display: flex;
} */

/* // */

a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

footer {
    background: #212529;
}

.footer-list>li {
    margin-top: 2px;
}

.component-wrapper {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

#dropdown-basic:focus {
    box-shadow: none;
}
