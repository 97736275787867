/* step-1 */
.create-form {
    padding: 30px 30px 30px 40px !important;
    transition: all .3s;
    border: solid 1px;
    border-radius: 15px;
    margin: auto;
    background: transparent;
    margin-top: 5%;
}

.form-group>label {
    margin-left: 10px;
}
#button_item {
    color: #222222;
}



.create-project {
    background: linear-gradient(-45deg, #fffbfb, #fffbfb, #fffbfb, #fffbfb, #fffbfb, #fffbfb, #fffbfb);
}

/* end */