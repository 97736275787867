.icon-navbar {
    margin-right: 10px;
    width: 25px;
    height: 25px;
    color: black !important;
}

.dashboard {
    background-color: #f5f4f3;
}

.sidebar {
    background-color: #EFF1F8;
    width: fit-content;
    /* background-image: linear-gradient(147deg, #000000 0%, #434343 74%); */

}

.sidenav---sidenav-toggle---1KRjR {
    color: black !important;

}

.sidenav---collapsed---LQDEv {
    transition: 500ms;
    border-right: 1px solid #f4f9fd;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
    border-right: 1px solid #f4f9fd;
    transition: 500ms;
}

.sidenav---navtext---1AE_f>p {
    color: black !important;
}

.sidenav---sidenav-toggle---1KRjR>span {
    background-color: black !important;

}

.noproject {
    width: 400px;
    height: 450px;
}

/*end*/
::-webkit-scrollbar {
    width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #606060;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #909090;
}

#sortby {
    background-color: transparent !important;
    border: 2px solid gray;
    border-radius: 10px;
    margin-right: 30px;
    color: #cfe4f5;
}


.list-img {
    width: 145px;
    height: 70px;
    border-radius: 10px;
    margin-left: 10px;
    margin-top: 5px
}


#list-ul-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    border-width: thin;
    padding-bottom: 8px;
    width: 1220px;
}


.img-des->:last-child {
    font-weight: lighter;
}

#ellipsis {
    color: black;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: fit-content;
    max-width: 500px;
    overflow: hidden;
    opacity: 0.8;
    width: 300px;
}

.dropdown-toggle:empty::after {
    color: #ffffff;
}

.list-btn>button,
.list-btn>a {
    width: 40px !important;
    height: 40px !important;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    border: 2px solid #222222;
    background-color: #222222;
    border-radius: 15px;
    margin-left: 15px;
}

/* 




.list-btn>button>a {
    display: inherit;
    height: 40px;
}

.delete-button {
    display: flex;
} */

/* // */

a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

footer {
    background: #212529;
}

.footer-list>li {
    margin-top: 2px;
}

.component-wrapper {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

#dropdown-basic:focus {
    box-shadow: none;
}