.apilist-title {
    margin-left: 5%;
    font-size: xx-large;
    margin-bottom: 15px;
}

.apilist-body {
    margin-left: 10%;
    padding-bottom: 0;
}

.apilist-list {
    font-size: 17px;
    /* margin-left: 10%; */
    margin-bottom: 0;

}

.apilist-span {
    color: red
}

.apilist-api {
    margin-left: 13%;
    margin-bottom: 0;
}

.apilist-api2 {
    margin-left: 15%;
    margin-bottom: 0;

}

.apilist-api3 {
    margin-left: 17%;
    margin-bottom: 0;

}

.apilist-api4 {
    margin-left: 19%;
    margin-bottom: 0;

}

.apilist-api5 {
    margin-left: 21%;
    margin-bottom: 0;

}

.apilist-api6 {
    margin-left: 23%;
    margin-bottom: 0;

}

.api-wrapper-main {
    width: 100%;
    display: flex;
    justify-content: center;
}

.api-wrapper {
    display: flex;
    justify-content: space-between;
    /* background-color: red; */
    width: 80%;
}

.api-copy {
    background-color: #f0f0f0;
    width: fit-content;
    margin-bottom: 10px;
    /* margin-left: 5% !important; */
}

.copy-button {
    position: absolute;
    right: 0;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}
