.header {
    width: 100%;
    height: 80px;
    color: #ecf0f0;
    background: linear-gradient(147deg, #000000 0%, #434343 74%);
    display: block;
    border-bottom: 1px solid #ecf0f0;
    padding-bottom: 10px;

}

.logo {
    height: 80px;
}

#username {
    height: 80px;
}

#username>p>span {
    color: #cbaf13;
    font-weight: 500;
}

#username>p {
    position: relative;
    margin: auto;
    width: fit-content;
    top: 25px;
    font-size: large;
    color: #000000;

}

.dev-center,
.profile {
    background-color: transparent;
    color: #000000;
    position: relative;
    margin-right: 15px;
    border: 1px solid;
    border-radius: 40px;

}

.support-group,
.profile-group {
    display: flex;
    justify-content: flex-end;
    height: 80px;
}

.support-group>button>a {
    color: #000000;
    font-family: 'Nunito' !important;
    font-size: 18px !important;
    text-transform: capitalize;

}

#basic-button-text {
    text-transform: capitalize;
    font-family: 'Nunito' !important;
    font-size: 22px !important;
    font-weight: bolder;
}

#basic-button-text {
    color: white !important;
}

#basic-button-text:hover {
    color: #fe4066 !important;
}

#logout {
    color: red;
}

.profile-btn {
    margin-right: 10px;
}

.profile>img {
    width: 30px;
    height: 30px;
    border: 1px solid;
    color: #f2d001;
    border-radius: 50%;
    margin-right: 5px;
}