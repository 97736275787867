/* step-2 */
.upload-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 100px;
}

h3 {
    text-align: center;
}

#logo {
    width: 120px;
    height: 80px;
}

.creating {
    font-size: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #1976D2;
    z-index: 999;
}

/* end */