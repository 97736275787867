#header {
  z-index: 999 !important;
  position: relative;
  width: 100%;
  top: 0;
  position: sticky

}

#footer {
  left: 0;
  bottom: 0;
  width: 100%;
  /* margin-top: 40px; */
}

.auth-wrapper {
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.body {
  position: relative;
  /* pointer-events: none; */
}

.des {
  text-align: center;
  color: #222222;
}

#toSignup:hover {
  text-decoration: underline;
  cursor: pointer;
}

.pending {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  opacity: 0.5;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
  position: relative;
}

.pending-icon__active {
  width: 100px !important;
  height: 100px !important;
  position: absolute !important;
  top: 30% !important;
  left: 40% !important;
  z-index: 99999;

}

.pending-icon__inactive {
  display: none !important;
}

.logo_out {
  width: 500px;
  height: 500px;
  align-content: center;
}
#engine{
  display: flex;
  justify-content: center;
}