/*editor */
#editor-frame {
    height: 100vh;
    width: 100%;
}

.header-tools {
    height: 50px;
    width: 100%;
    position: fixed;
    margin-top: 10px;
}

.header-title {
    text-align: center;
    font-size: x-large;
    color: white;
    font-weight: bold;
    pointer-events: none;

}

.thumbnail-image.active {
    border: solid
}


/* thumbnail */

.thumbnail {
    margin: auto;
    margin-top: 15px;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    width: 100%;
    border: 1px solid;
    border-radius: 35px;
    margin-bottom: 10px;
    color: #e5e2e2;
    padding-top: 5px;
    max-width: max-content;
    /* overflow: auto; */
    white-space: nowrap;

}

.thumbnail_small_screen {
    margin: auto;
    margin-top: 15px;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    width: fit-content;
    border: 1px solid;
    border-radius: 35px;
    margin-bottom: 10px;
    color: #e5e2e2;
    padding-top: 5px;
    max-width: 80%;
}

#img {
    width: 120px;
    height: 50px;
    display: block;
    border-radius: 5px;
    margin-right: 10px;
}

#img:active {
    box-shadow: 0 0 3pt 2pt cornflowerblue
}

.arrow {
    height: 50px;
    width: 50px;
    background: transparent;
    cursor: pointer;
    transition: transform ease-in .1s;
    display: inline-block;
}

.bi-arrow-left-short:before,
.bi-arrow-right-short:before {
    color: #222
}


/* thumbnail */

.thumbnail-wrapper {
    position: relative;
}

.thumbnail-wrapper:hover .thumbnail-image-remove {
    opacity: 1;
}

.thumbnail-image-remove {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -15px;
    opacity: 0;
    transition: .5s ease;
}

.thumbnail-image-remove-icon {
    position: relative;
    top: -6px;
    left: -10px;
}

.thumbnail-image:hover .thumbnail-image-remove {
    display: block;
}

.thumbnail-image-add-button {
    margin-bottom: 15px;
}

.thumbnail-image {
    z-index: 99999999999;
}


/* end thumbnail */


/* add label */

.picked-location-label {
    position: absolute;
    background: white;
}

.CreateLabelForm {
    width: 300px;
    height: 260px;
    border: 1px solid #e5e2e2;
    border-radius: 25px;
    background-color: transparent;
}

.CreateLinkForm {
    width: 300px;
    height: 160px;
    border: 1px solid #e5e2e2;
    border-radius: 25px;
    background-color: transparent;
}

.CreateLabelForm__options {
    margin-top: 13px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #e5e2e2;
}

.tools {
    width: 100%;
    position: fixed;
    z-index: 10;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 30px;
}

.toolsbox>input {
    width: 250px;
}

.toolsbox>textarea {
    width: 250px;
    height: 90px;
}

.form-group>textarea {
    height: 100px;
    background-color: #e5e2e2 !important;
}

.form-group>input {
    background-color: #e5e2e2 !important;
}

.custom-controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}

.hotspot-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 15px;
}

.spot-text-ele,
.spot-img-ele {
    position: absolute;
    min-width: 310px;
    max-width: 310px;
    min-height: 44px;
    color: #fff;
    background-color: rgba(0, 0, 0, .85);
    word-wrap: break-word;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
    padding: 12px;
    pointer-events: auto;
    cursor: auto;
}

.text-ele__describe {
    font-size: 15px;
    font-weight: 500;
    -webkit-text-size-adjust: none;
    color: #c8c8c8;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
}

.spot-text-ele {
    top: -6% !important;
}

.spot-img-ele {
    top: -15.5% !important;
}

.spot-vid-ele {
    top: -17% !important
}

.img-ele__img {
    width: fit-content;
    height: fit-content;
}

/* end label*/

/* linkspot */
.picked-location-link {
    position: absolute;
    background: white;
}

.preview-spot {
    text-align: center;
    position: absolute;
    bottom: -95px;
    left: -115px;
}

/* end linkspot */

/* header tools */

.vid-ele__vid {
    height: max-content !important;
    width: max-content !important;
}

.text-ele__title,
.img-ele__title,
.vid-ele__title {
    font-size: 16px;
    font-weight: 700;
    white-space: pre-wrap;
    margin: 0;
}

.toolsbox>.MuiDropzoneArea-root {
    min-height: 20px !important;
    max-height: 90px !important;
    height: 86.9px !important;
    width: 268px !important;
}

.toolsbox>.MuiDropzoneArea-root>.MuiDropzoneArea-textContainer>p {
    font-size: 1rem !important;
    margin-top: 15px !important;
}

.toolsbox>.MuiDropzoneArea-root>.MuiDropzoneArea-textContainer>svg {
    width: 25px !important;
    height: 25px !important;
    display: none;
}

.toolsbox__pickimage {
    min-width: 250px;
    height: 70px;
    margin-bottom: 15px;
}

/* end tools */
.img-wrapper {
    display: flex;
    overflow: hidden;
}

.adjustY {
    transform: translateY(355px);
}

.adjustXleft {
    transform: translate(170px, 160px)
}

.adjustXRight {
    transform: translate(-170px, 160px)
}

.adjustBotLeft {
    transform: translate(170px, 20px)
}

.adjustBotRight {
    transform: translate(-170px, 20px)
}


.preview-link {
    position: absolute;
    min-width: 310px;
    max-width: 310px;
    min-height: 44px;
    color: #fff;
    background-color: rgba(0, 0, 0, .85);
    word-wrap: break-word;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
    padding: 12px;
    pointer-events: auto;
    cursor: auto;
    top: -15.9% !important;
    /* left: -0.% !important; */
}

/*tooltip */
/* .preview-link::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 45%;
    border-color: rgba(0, 0, 0, .85) transparent transparent transparent;
    border-style: solid;
    border-width: 15px;
} */

.preview-image {
    width: 286px;
    height: 200px;
}

.editinfo-dropzone>.MuiDropzoneArea-root {
    min-height: 20px !important;
    max-height: 90px !important;
    height: 86.9px !important;
}

.editinfo-dropzone>.MuiDropzoneArea-root>.MuiDropzoneArea-textContainer>p {
    font-size: 1rem !important;
    margin-top: 15px !important;
    margin-bottom: 0 !important;
}

.editinfo-dropzone>.MuiDropzoneArea-root>.MuiDropzoneArea-textContainer>svg {
    width: 25px !important;
    height: 25px !important;
    display: none;
}



.transition-start {
    filter: blur(0.5rem);
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

.transition-end {
    filter: blur(0);
    -webkit-transition: all 0.5s ease-in-out;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
}