/* step-control */
.step-button {
    display: flex;
    justify-content: space-around;

}

.step-button>button {
    margin-top: 10px;
    width: 100px;
}

/* end */